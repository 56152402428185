/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/mdui@1.0.1/dist/js/mdui.min.js
 * - /npm/jquery@3.6.0/dist/jquery.slim.min.js
 * - /gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/pjax@0.2.8/pjax.min.js
 * - /gh/buunguyen/topbar@0.1.3/topbar.min.js
 * - /npm/prismjs@1.27.0/plugins/normalize-whitespace/prism-normalize-whitespace.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
